<template>
    <div ref="scrollContainer" class="c-zoom-viewer">
        <div
            :class="{
                'c-zoom-viewer__images': true,
                'o-row c-zoom-viewer__images--row': imagesNotFullWidth,
            }"
        >
            <div class="o-large--1 o-large--offset-2">
                <div
                    :id="`c-zoom-viewer-${uuid}__thumbnails`"
                    :class="{
                        'c-zoom-viewer__thumbnails': true,
                        'c-zoom-viewer__thumbnails--fixed': imagesFullWidth,
                    }"
                >
                    <div
                        v-for="(image, index) of images"
                        :key="`imageViewerThumbnail-${index}`"
                        :class="{
                            'c-zoom-viewer__thumbnail-container': true,
                            'c-zoom-viewer__thumbnail-container--active': activeThumbnail === index
                        }"
                    >
                        <ProductImage
                            :id="`zoom-thumbnail-${index}`"
                            v-tab-focus.onspace="() => scrollToImage(`zoom-image-${index}`, true)"
                            :src="image"
                            :choiceId="choiceId"
                            :title="`${title} zoom thumbnail ${index}`"
                            :imagePreset="imagePresets.thumb"
                            class="c-zoom-viewer__thumbnail"
                        />
                    </div>
                </div>
            </div>
            <div :class="{ 'o-extra-small--12': true, 'o-large--7': imagesNotFullWidth }">
                <div
                    v-for="(image, index) of images"
                    :id="`zoom-image-${index}`"
                    :key="`imageViewer-${index}`"
                    v-tab-focus="popModal"
                    class="c-zoom-viewer__img-container"
                    aria-label="close modal"
                >
                    <ProductImage
                        :ref="setImageRefs"
                        :src="image"
                        :choiceId="choiceId"
                        :title="`${title} zoom image ${index}`"
                        :imagePreset="imagePresets.zoom"
                        class="c-zoom-viewer__img"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapMutations } from 'vuex';
import { get, uniqueId } from 'lodash-es';

import { GLOBAL_IMAGE_PRESETS } from '~coreModules/core/js/constants';

import { MODALS_MODULE_NAME, POP_MODAL } from '~coreModules/modals/js/modals-store';
import ProductImage from '~coreModules/core/components/ui/ProductImage.vue';

export default {
    name: 'ImageViewerZoom',
    components: {
        ProductImage,
    },
    props: {
        images: {
            type: Array,
            required: true,
        },
        imagesFullWidth: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            required: true,
        },
        selectedImage: {
            type: Number,
            required: true,
        },
        choiceId: {
            type: String,
            required: true,
        },
        pdpImageViewerZoomModalId: {
            type: String,
            required: true,
        },
    },
    data() {
        const uuid = uniqueId();
        return {
            thumbnailsElement: null,
            scrollContainerEl: null,
            activeThumbnail: 0,
            uuid,
            imageRefs: [],
            imagePresets: GLOBAL_IMAGE_PRESETS.product,
        };
    },
    computed: {
        imagesNotFullWidth() {
            return !this.imagesFullWidth && this.$mediaQueries.isLargish;
        },
    },
    watch: {
        '$route.hash': {
            handler(newHash, oldHash) {
                /* if the pdp zoom modal hash was removed, close this modal */
                if (oldHash && oldHash.includes(this.pdpImageViewerZoomModalId) && !newHash) {
                    this.popModal();
                }
            },
        },
    },
    beforeUpdate() {
        this.imageRefs = [];
    },
    mounted() {
        this.scrollContainerEl = this.$refs.scrollContainer;
        this.scrollContainerEl.addEventListener('scroll', this.updateActiveThumbnail);

        if (this.selectedImage !== 0) {
            this.scrollToImage(`zoom-image-${this.selectedImage}`);
        }

        // the CSS transform applied to the modal containers makes it impossible to have the thumbnail container
        // positioned absolutely. This block takes the rendered thumbnails and appends them to div#app so that the
        // stacking context is outside of the transformed container
        const { uuid } = this;
        this.thumbnailsElement = document.getElementById(`c-zoom-viewer-${uuid}__thumbnails`);

        if (this.imagesFullWidth) {
            document.getElementById('app').appendChild(this.thumbnailsElement);
        }
    },
    unmounted() {
        const hash = get(this.$route, 'hash', '');

        /* If this modals hash ID is in the URL, remove it by navigating back in history (like clicking back button) */
        if (hash && hash.includes(this.pdpImageViewerZoomModalId)) {
            this.$router.go(-1);
        }

        this.scrollContainerEl.removeEventListener('scroll', this.updateActiveThumbnail);

        // ensure the thumbnail container is destroyed along with this component
        if (this.thumbnailsElement) {
            this.thumbnailsElement.remove();
        }
    },
    methods: {
        ...mapMutations(MODALS_MODULE_NAME, {
            popModal: POP_MODAL,
        }),
        setImageRefs(el) {
            if (el) {
                this.imageRefs.push(el);
            }
        },
        scrollToImage(imageId, smooth = false) {
            const selectedImage = this.$el.querySelector(`#${imageId}`);

            this.scrollContainerEl.scrollTo({
                top: selectedImage.offsetTop,
                behavior: smooth ? 'smooth' : 'auto',
            });
        },
        updateActiveThumbnail() {
            window.requestAnimationFrame(() => {
                for (let index = 0; index < this.images.length; index++) {
                    // an image is considered to be 'visible' if the onscreen portion is > half of total modal height
                    const currentPic = this.imageRefs[index].$el.getBoundingClientRect();
                    const halfVisible = currentPic.bottom > (this.scrollContainerEl.getBoundingClientRect().height / 2);

                    if (halfVisible) {
                        this.activeThumbnail = index;
                        break;
                    }
                }
            });
        },
    },
};
</script>

<style lang='scss'>
    .c-zoom-viewer {
        $this: &;

        height: 100%;
        width: 100%;
        overflow: scroll;

        &__img-container {
            position: relative;
            background-color: $nu-secondary;
            width: 100%;
            padding-top: 150%;
            cursor: pointer;
        }

        &__img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        &__thumbnails {
            display: none;
            flex-direction: column;
            justify-content: center;
        }

        &__thumbnail-container {
            // container size is the thumbnail size + 4px of height/width so there's room for the 2px border
            height: 100px;
            width: 68px;
            margin-bottom: $nu-spacer-1;
            position: relative;

            &:hover,
            &--active {
                #{$this}__thumbnail {
                    border-color: $nu-white;
                }
            }

            &:hover:not(&--active) {
                #{$this}__thumbnail {
                    border-width: 1px;
                }
            }

            &--active {
                #{$this}__thumbnail {
                    border-width: 2px;
                }
            }
        }

        &__thumbnail {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;

            height: 96px;
            width: 64px;
            border: 1px solid transparent;
            cursor: pointer;
            box-sizing: content-box;
            transition: border 0.2s ease;
        }

        &__thumbnails--fixed {
            @include breakpoint(medium) {
                position: fixed;
                display: flex;
                top: 0;
                bottom: 0;
                left: $nu-spacer-3;
                z-index: map-get($zindex, modal);
            }
        }

        &__images--row {
            #{$this}__thumbnail-container {
                width: 100%;
                height: auto;

                &:hover,
                &--active {
                    #{$this}__thumbnail {
                        border-color: $nu-primary;
                        border-width: 2px;
                    }
                }
            }

            #{$this}__thumbnail {
                position: relative;
                width: 100%;
                height: auto;
                border: 2px solid transparent;
            }

            @include breakpoint(large) {
                margin-top: $nu-spacer-5;

                #{$this}__thumbnails {
                    position: sticky;
                    top: $nu-spacer-5;
                    display: flex;
                }
            }
        }
    }
</style>
